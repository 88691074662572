import React, {createContext, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    deleteObject,
    getBytes,
    getDownloadURL,
    getStorage,
    listAll,
    ref,
    uploadBytes
} from "firebase/storage";
import {FirebaseContext} from "../../App";
import {Button, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import {CategoryCards} from "./CategoryCards";
import {TextsCard} from "./TextsCard";
import {MemeCard} from "./MemeCard";
import {IoMdArrowBack} from "react-icons/io";
import {TestHeader} from "./TestHeader";
import {TestOption, TestType} from "./TestType";
import {GlobalType} from "./GlobalType";

export const DataContext = createContext({
    data: {} as TestType,
    setData: (_: TestType) => {
    },
});

export function InspectTest() {
    const [loading, setLoading] = useState(true);
    const [globalData, setGlobalData] = useState<GlobalType>({} as GlobalType);
    const [data, setData] = useState<TestType>({} as TestType);
    const [testName, setTestName] = useState("");
    const [dragging, setDragging] = useState(false);
    const [originalData, setOriginalData] = useState<TestType>({} as TestType);
    const [selectedResult, setSelectedResult] = useState(0);
    const {id} = useParams();
    const firebase = useContext(FirebaseContext);
    const storage = getStorage(firebase);
    const navigate = useNavigate();


    useEffect(() => {
        void loadTestData();
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        }
    }, []);

    function alertUser(e: BeforeUnloadEvent) {
        e.preventDefault();
        e.returnValue = '';
    }

    async function loadTestData() {
        const buffer = await getBytes(ref(storage, `/tests/${id}/data.json`));
        const decoder = new TextDecoder("utf-8");
        const data: TestType = JSON.parse(decoder.decode(buffer));

        if (!data.testOption) {
            data.testOption = TestOption.Score;
        }
        setOriginalData(JSON.parse(JSON.stringify(data)));

        setData(data);
        setTestName(data.texts.title);

        const globalBuffer = await getBytes(ref(storage, '/tests.json'));
        const tests = JSON.parse(decoder.decode(globalBuffer));
        setGlobalData(tests);
        setLoading(false);

        const nameToIdMapping: Record<string, string> = localStorage.getItem("nameToIdMapping") ? JSON.parse(localStorage.getItem("nameToIdMapping") as string) : {};
        nameToIdMapping[id as string] = data.texts.title;
        localStorage.setItem("nameToIdMapping", JSON.stringify(nameToIdMapping));

        if (!data.categoryOrder) {
            data.categoryOrder = [...new Set(data.items.map(item => item.category))];
        }
        if (data.texts.meme !== undefined || data.texts.results.filter(r => r.meme !== undefined).length === 0) {
            setSelectedResult(-1);
        }
    }

    function undoMeme(index: number) {
        if (!index || index === -1) {
            const newData = {...data};
            newData.texts.meme = originalData.texts.meme;
            setData(newData);
        } else {
            const newData = {...data};
            newData.texts.results[index].meme = originalData.texts.results[index].meme;
            setData(newData);
        }
    }

    function addCategory() {
        const newData = {...data};
        const newName = getNewCategoryName();
        newData.items.push({name: "First Question", category: newName});
        newData.categoryOrder.push(newName);
        setData({...newData});
    }

    function getNewCategoryName(currentCount = 0): string {
        if (data.items.filter(item => item.category === `New category${currentCount}`).length === 0) {
            return `New category${currentCount}`;
        } else {
            return getNewCategoryName(currentCount + 1);
        }
    }

    async function saveTest() {
        if (data.category !== originalData.category) {
            const newGlobalData = {...globalData};
            newGlobalData.tests = newGlobalData.tests.map(test => {
                if (test.id === id) {
                    test.category = data.category;
                }
                return test;
            });
            console.log(newGlobalData);
            await uploadBytes(ref(storage, '/tests.json'), new TextEncoder().encode(JSON.stringify(newGlobalData)));
            setGlobalData(newGlobalData);
        }
        setLoading(true);
        const oldTitle = data.texts.title;
        data.texts.title = testName;

        if (oldTitle !== testName) {
            const nameToIdMapping = localStorage.getItem("nameToIdMapping") ? JSON.parse(localStorage.getItem("nameToIdMapping") as string) : {};
            nameToIdMapping[id as string] = data.texts.title;
            localStorage.setItem("nameToIdMapping", JSON.stringify(nameToIdMapping));
        }

        if (typeof data.texts.meme === typeof {}) {
            const fileType = (data.texts.meme as any).type.split('/').pop();
            await uploadBytes(ref(storage, `/tests/${id}/images/meme.${fileType}`), data.texts.meme as any);
            data.texts.meme = await getDownloadURL(ref(storage, `/tests/${id}/images/meme.${fileType}`));
            void cleanOldMemes(true);
        } else {
            for (const result of data.texts.results) {
                if (typeof result.meme === typeof {}) {
                    await uploadBytes(ref(storage, `/tests/${id}/images/${(result.meme as any).name}`), result.meme as any);
                    result.meme = await getDownloadURL(ref(storage, `/tests/${id}/images/${(result.meme as any).name}`));
                }
                void cleanOldMemes(false);
            }
        }


        const encoder = new TextEncoder();
        const buffer = encoder.encode(JSON.stringify(data));
        await uploadBytes(ref(storage, `/tests/${id}/data.json`), buffer);
        setLoading(false);
    }

    async function deleteFromUrl(url: string) {
        const path = (url.split("/").pop() as string).split("?")[0] as string;
        const parsedPath = path.replaceAll("%2F", "/");
        await deleteObject(ref(storage, parsedPath));
    }

    async function cleanOldMemes(memeUnity: boolean) {
        if (memeUnity) {
            for (const result of originalData.texts.results) {
                if (result.meme) {
                    await deleteFromUrl(result.meme as string);
                }
            }
        } else {
            if (originalData.texts.meme) {
                await deleteFromUrl(originalData.texts.meme as string);
            }
        }

    }

    async function deleteTest() {
        setLoading(true);
        await deleteFolderContent();
        if (globalData.tests.filter(t => t.id === id).length > 0) {
            const newGlobalData = {...globalData};
            newGlobalData.tests = newGlobalData.tests.filter(t => t.id !== id);
            await uploadBytes(ref(storage, '/tests.json'), new TextEncoder().encode(JSON.stringify(newGlobalData)));
            setGlobalData(newGlobalData);
        }
        setLoading(false);
        navigate("/");
    }

    async function deleteFolderContent() {
        await deleteObject(ref(storage, `/tests/${id}/data.json`));
        const images = await listAll(ref(storage, `/tests/${id}/images/`));
        for (const image of images.items) {
            await deleteObject(ref(storage, `/tests/${id}/images/${image.name}`));
        }
    }

    async function publishTest() {
        setLoading(true);
        const test = {
            id: id as string,
            name: data.texts.title,
            category: data.category,
            location: `/tests/${id}`,
        }
        if (globalData.tests.filter(test => test.id === id).length === 0) {
            globalData.tests.push(test);
        }
        const encoder = new TextEncoder();
        const uploadBuffer = encoder.encode(JSON.stringify(globalData));
        await uploadBytes(ref(storage, '/tests.json'), uploadBuffer);
        setLoading(false);
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <DataContext.Provider value={{data, setData}}>
            <Button color="primary" onClick={() => navigate("/")}><IoMdArrowBack/></Button>
            <Container>
                <FormGroup>
                    <Label tag="h3">Name:</Label>
                    <Input value={testName} onChange={e => setTestName(e.target.value)}/>
                </FormGroup>
                <TestHeader categories={globalData.categories}/>
                <CategoryCards dragging={dragging} setDragging={setDragging}/>
                <div style={dragging ? {display: "none"} : undefined}>
                    <Button color="primary" onClick={addCategory}
                            style={{width: "100%", marginBottom: "10%"}}>Add Question
                        Category</Button>
                    <TextsCard texts={data.texts} selectedResult={selectedResult}
                               setSelectedResult={setSelectedResult}/>
                    <MemeCard undoMeme={undoMeme} selectedResult={selectedResult}
                              setSelectedResult={setSelectedResult}/>
                </div>
            </Container>
            <Row className="inspect-footer">
                <Col>
                    <Button color="success" onClick={saveTest} disabled={loading}>Save</Button>
                </Col>
                {globalData.tests.filter(t => t.id === id).length === 0 ? <Col>
                    <Button color="warning" onClick={publishTest}>Publish</Button>
                </Col> : null}
                <Col>
                    <Button color="danger" onClick={deleteTest}>Delete</Button>
                </Col>
            </Row>
        </DataContext.Provider>
    )
}