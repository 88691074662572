import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import {ResultPart} from "./ResultPart";
import {DataContext} from "./InspectTest";
import React, {useContext, useEffect, useState} from "react";
import {TestItem, TestOption, TextsType} from "./TestType";

export function TextsCard({
                              texts,
                              selectedResult,
                              setSelectedResult
                          }: { texts: TextsType, selectedResult: number, setSelectedResult: (_: number) => void }) {
    const [minWeight, setMinWeight] = useState(0);
    const [maxWeight, setMaxWeight] = useState(0);
    const {data, setData} = useContext(DataContext);

    useEffect(() => {
        const newOrdered = [] as Array<TestItem>[];
        data.categoryOrder.forEach(category => {
            newOrdered.push(data.items.filter(question => question.category === category));
        });
        if (data.testOption === TestOption.Weighted) {
            let min = 0;
            let max = 0;
            newOrdered.forEach(category => {
                min += Math.min(...category.map(question => question.weight || 0));
                max += Math.max(...category.map(question => question.weight || 0));
            });
            setMaxWeight(max);
            setMinWeight(min);
        } else {
            if (data.testOption === TestOption.Quiz) {
                let correct = 0;
                newOrdered.forEach(category => {
                    correct += category.filter(question => question.correct).length;
                });
                setMaxWeight(correct);
            } else {
                setMaxWeight(newOrdered.reduce((acc, category) => acc + category.length, 0));
            }
        }
    }, [data]);

    function editDescription(newText: string) {
        const newData = {...data};
        newData.texts.description = newText;
        setData({...newData});
    }

    function editResult(id: number, newText?: string, key?: string) {
        const newData = {...data};
        if (newText === null) {
            delete newData.texts.results[id];
        } else if (id === newData.texts.results.length) {
            newData.texts.results.push({"title": "new result", "body": "hello"});
        } else {
            (newData.texts.results[id] as Record<string, string>)[key as string] = newText as string;
        }
        setData({...newData});
    }

    return (
        <Card style={{marginBottom: "50px"}}>
            <CardHeader tag="h3" style={{textAlign: "center"}}>Texts</CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input type="text" value={texts.description ?? ""}
                               onChange={e => editDescription(e.target.value)}/>
                    </FormGroup>
                    <hr/>
                    <h2>Results:</h2>
                    {data.testOption === TestOption.Weighted ?
                        <h6 style={{textDecoration: "underline"}}>min weight {minWeight} and max weight {maxWeight}</h6> : null}
                    {data.testOption !== TestOption.Weighted ?
                        <h6 style={{textDecoration: "underline"}}>max score {maxWeight}</h6> : null}
                    {texts.results.map((result, i) => {
                        return <ResultPart key={i} editResult={editResult}
                                           selected={i === selectedResult} index={i}
                                           setSelected={setSelectedResult}
                                           memeUnity={selectedResult === -1}/>
                    })}
                </Form>
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={() => editResult(texts.results.length)}
                        style={{width: "100%"}} title="add a new result">Add
                    Result</Button>
            </CardFooter>
        </Card>
    )
}