import {
    Button,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {DataContext} from "./InspectTest";
import {useContext, useState} from "react";
import {FirebaseContext} from "../../App";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import React from "react";

export function ImageSelector({
                                  questionId,
                                  toggleModal
                              }: { questionId: number, toggleModal: () => void }) {
    const {data, setData} = useContext(DataContext);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [updated, setUpdated] = useState(false);
    const [value, setValue] = useState(data.items[questionId].description);
    const [key, setKey] = useState(Math.random().toString(36));

    const firebase = useContext(FirebaseContext);

    function handleFileChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFile((ev.target.files as FileList)[0]);
        setUpdated(true);
    }

    function handleValueChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setValue(ev.target.value);
        setUpdated(true);
    }

    function handleImageNotExist() {
        const newData = {...data};
        delete newData.items[questionId].image;
        setData(newData);
    }

    async function removeImage() {
        if (!data.items[questionId].image) {
            return;
        }
        const storage = getStorage(firebase);
        const path = ((data.items[questionId].image as string).split("/").pop() as string).split("?")[0];
        const parsedPath = path.replaceAll("%2F", "/");
        await deleteObject(ref(storage, parsedPath));
        setSelectedFile(null);
        const newData = {...data};
        delete newData.items[questionId].image;
        setData(newData);
        setKey(Math.random().toString(36));
    }

    async function saveData(e: React.MouseEvent) {
        e.preventDefault();

        if (!selectedFile && value === data.items[questionId].description) {
            toggleModal();
            return;
        }

        const newData = {...data};

        if (selectedFile) {
            const storage = getStorage(firebase);
            const fileName = data.items[questionId].name.replaceAll(" ", "_");
            const id = window.location.href.split("/").pop();
            const fullFilePath = `${fileName}.${selectedFile.type.split("/").pop()}`;
            const response = await uploadBytes(ref(storage, `/tests/${id}/images/${fullFilePath}`), selectedFile);
            const reference = response.metadata.ref;
            newData.items[questionId].image = await getDownloadURL(reference!);
        }
        if (value !== data.items[questionId].description) {
            newData.items[questionId].description = value;
        }

        setData({...newData});
        toggleModal();
    }

    return (
        <Modal isOpen={true} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Select image and enter description
                for {data.items[questionId].name}</ModalHeader>
            <ModalBody align="center">
                <img style={{width: "50%", height: "auto"}}
                     src={updated ? URL.createObjectURL(selectedFile!) : data.items[questionId].image}
                     alt=""
                     onError={handleImageNotExist}/>
                <FormGroup align="left" style={{marginTop: "15px"}}>
                    <Label tag="h4">Description</Label>
                    <Input type="textarea" value={value} onChange={handleValueChange}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <InputGroup>
                    <Input type="file" onChange={handleFileChange} style={{marginRight: "20px"}}
                           accept={".jpg, .jpeg, .png"} key={key}/>
                    {updated ? <Button color="success" onClick={saveData}>Save</Button> : null}
                    {data.items[questionId].image ?
                        <Button color="danger" onClick={removeImage}>Delete Image</Button> : null}
                </InputGroup>
            </ModalFooter>
        </Modal>
    )
}