import React, {useContext, useEffect, useState} from "react";
import {SingleCCard} from "./SingleCCard";
import {DataContext} from "./InspectTest";
import {Spinner} from "reactstrap";
import {TestItem} from "./TestType";

export function CategoryCards({
                                  dragging,
                                  setDragging
                              }: { dragging: boolean, setDragging: (dragging: boolean) => void }) {
    const [orderedQuestions, setOrderedQuestions] = useState<Array<Array<TestItem>>>([]);
    const [draggingIndex, setDraggingIndex] = useState(0);
    const [lastOrderLength, setLastOrderLength] = useState(0);

    const {data, setData} = useContext(DataContext);

    useEffect(() => {
        const newOrdered = [] as Array<TestItem>[];
        const newItems = [] as Array<TestItem>;
        data.categoryOrder?.forEach(category => {
            const currItems = data.items.filter(question => question.category === category);
            newOrdered.push(currItems);
            newItems.push(...currItems);
        });
        data.items = newItems;
        setOrderedQuestions(newOrdered);
        setLastOrderLength(data.categoryOrder.length);
    }, [data.categoryOrder.length]);

    function allowDrop(ev: React.DragEvent<HTMLHRElement>) {
        ev.preventDefault();
    }

    function drag(ev: React.DragEvent<HTMLHRElement>) {
        ev.dataTransfer.setData("text", (ev.target as HTMLHRElement).id);
        setTimeout(function () {
            setDragging(true);
            setDraggingIndex(data.categoryOrder.indexOf((ev.target as HTMLHRElement).id));
        }, 10);

    }

    function drop(ev: React.DragEvent<HTMLHRElement>) {
        ev.preventDefault();
        const category = ev.dataTransfer.getData("text");
        moveCategory(category, parseInt((ev.target as HTMLHRElement).id));
        setDragging(false);
    }

    function moveCategory(category: string, target: number) {
        const newData = {...data};
        const newOrder = [...newData.categoryOrder];
        const index = newOrder.indexOf(category);
        newOrder.splice(index, 1);
        newOrder.splice(target, 0, category);
        newData.categoryOrder = newOrder;
        setData({...newData});
    }

    if (lastOrderLength !== data.categoryOrder.length) {
        return <Spinner/>;
    }

    const cards: JSX.Element[] = [];
    let index = 0;
    orderedQuestions.forEach((e, i) => {
        if (i < draggingIndex) {
            cards.push(<hr className="drop-zone" style={dragging ? undefined : {display: "none"}} key={i}
                           onDrop={drop}
                           onDragOver={allowDrop} id={`${i}`}/>);
        }
        cards.push(<SingleCCard key={e[0].category} questions={e} startIndex={index}
                                dragStart={drag} dragEnd={() => setDragging(false)}
                                dragging={dragging}/>);
        if (i > draggingIndex) {
            cards.push(<hr className="drop-zone" style={dragging ? undefined : {display: "none"}} key={i}
                           onDrop={drop}
                           onDragOver={allowDrop} id={`${i}`}/>);
        }
        index += e.length;
    });

    return (<React.Fragment>{cards}</React.Fragment>);
}