import {createContext, useEffect} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Home} from "./components/Home/Home";
import {Login} from "./components/login/Login";
import {InspectTest} from "./components/Test/InspectTest";
import React from "react";
import {FirebaseApp} from "@firebase/app";

export const FirebaseContext = createContext<FirebaseApp>({} as FirebaseApp);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};

function App() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            console.log("User is not logged in");
            navigate("/login")
        }
    }, [user, loading]);
    return (
        <div className="App">
            <FirebaseContext.Provider value={app}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/edit/:id" element={<InspectTest/>}/>
                </Routes>
            </FirebaseContext.Provider>
        </div>
    );
}

export default App;
