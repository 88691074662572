import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup
} from "reactstrap";
import {SingleQuestion} from "./SingleQuestion";
import React, {useContext, useState} from "react";
import {AiOutlineCheck, AiOutlineEdit} from "react-icons/ai";
import {GiHamburgerMenu} from "react-icons/gi";
import {DataContext} from "./InspectTest";
import {TestItem, TestOption} from "./TestType";

export function SingleCCard({questions, startIndex, dragStart, dragEnd, dragging}: {
    questions: Array<TestItem>, startIndex: number, dragStart: (_: React.DragEvent<HTMLHRElement>) => void, dragEnd: (_: React.DragEvent) => void, dragging: boolean
}) {

    const [editingCategory, setEditingCategory] = useState(false);
    const [value, setValue] = useState(questions[0].category);
    const [beingDragged, setBeingDragged] = useState(false);

    const {
        data, setData
    }

        = useContext(DataContext);

    function handleEditButton() {
        if (editingCategory) {
            updateCategoryTitle(questions[0].category, value);
        }
        setEditingCategory(!editingCategory);
    }

    function startDrag(ev: React.DragEvent<HTMLHRElement>) {
        setTimeout(function () {
            setBeingDragged(true);
        }, 10);
        dragStart(ev);
    }

    function stopDragging(ev: React.DragEvent) {
        setBeingDragged(false);
        dragEnd(ev);
    }

    function deleteCategory(category: string) {
        const newData = {...data};
        newData.items = newData.items.filter(item => item.category !== category);
        newData.categoryOrder = newData.categoryOrder.filter(item => item !== category);
        setData({...newData});
    }

    function addQuestion(category: string) {
        const newData = {...data};
        newData.items.push({name: "", category: category});
        setData({...newData});
    }

    function updateCategoryTitle(oldTitle: string, newTitle: string) {
        const newData = {...data};
        newData.categoryOrder[newData.categoryOrder.indexOf(oldTitle)] = newTitle;
        newData.items = newData.items.map(item => {
            if (item.category === oldTitle) {
                item.category = newTitle;
            }
            return item;
        });
        setData({...newData});
    }


    return (
        <Card key={questions[0].category}
              style={beingDragged ? {display: "none"} : {marginBottom: "50px"}}>
            <Form>
                <CardHeader tag="h3" style={{textAlign: "center"}}>
                    <FormGroup>
                        <InputGroup onDragStart={startDrag} draggable={true}
                                    onDragEnd={stopDragging} id={questions[0].category}>
                            <GiHamburgerMenu size="0.75em"
                                             style={{marginTop: "1%", marginRight: "2%"}}/>
                            {editingCategory ? <Input type="text" value={value}
                                                      onChange={e => setValue(e.target.value)}/> : questions[0].category}
                            <Button color="primary" onClick={handleEditButton}
                                    style={editingCategory ? undefined : {
                                        right: "15px",
                                        position: "absolute",
                                        zIndex: 99
                                    }}>
                                {editingCategory ? <AiOutlineCheck/> : <AiOutlineEdit/>}
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </CardHeader>
                <CardBody style={dragging ? {display: "none"} : undefined}>
                    {questions.map((question, i) => {
                        return <SingleQuestion id={startIndex + i} key={startIndex + i}
                                               categories={data.categoryOrder.filter(c => c !== questions[0].category)}
                                               first={i === 0}
                                               last={questions.length - 1 === i}/>
                    })}
                </CardBody>
                <CardFooter style={dragging ? {display: "none"} : undefined}>
                    <Button color="primary" onClick={() => addQuestion(questions[0].category)}
                            style={{
                                width: "45%",
                                marginRight: "10%"
                            }}>{data.testOption === TestOption.Score ? "Add Question" : "Add Answer"}</Button>
                    <Button color="danger" onClick={() => deleteCategory(questions[0].category)}
                            style={{width: "45%"}}>Delete Category</Button>
                </CardFooter>
            </Form>
        </Card>
    )
}