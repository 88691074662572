import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup} from "reactstrap";
import {AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineDelete, AiOutlineFileImage} from "react-icons/ai";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {MdDriveFileMoveOutline} from "react-icons/md";
import {DataContext} from "./InspectTest";
import {ImageSelector} from "./ImageSelector";
import React from "react";
import {TestOption} from "./TestType";

export function SingleQuestion({id, categories, first, last}: { id: number, categories: string[], first: boolean, last: boolean }) {
    const {data, setData} = useContext<any>(DataContext);
    const [isOpen, setIsOpen] = useState(false);
    const [weight, setWeight] = useState(0);
    const [correct, setCorrect] = useState(false);
    const [showingModal, setShowingModal] = useState(false);

    useEffect(() => {
        setWeight(data.items[id].weight || 0);
        setCorrect(data.items[id].correct || false);
    }, [data]);


    function updateContent(newContent: string) {
        const newData = {...data};
        newData.items[id].name = newContent;
        setData({...newData});
    }

    function moveQuestionDirection(direction: number) {
        const newData = {...data};
        const newIndex = id + direction;
        const temp = newData.items[id];
        newData.items[id] = newData.items[newIndex];
        newData.items[newIndex] = temp;
        setData({...newData});
    }

    function moveQuestion(newCategory: string) {
        const newData = {...data};
        newData.items[id].category = newCategory;
        setData({...newData});
    }

    function removeQuestion(index: number) {
        const newData = {...data};
        newData.items.splice(index, 1);
        setData({...newData});
    }

    function updateWeight(e: ChangeEvent<HTMLInputElement>) {
        const newWeight = parseInt(e.target.value.slice(0, 2));
        setWeight(newWeight);
        const newData = {...data};
        newData.items[id].weight = newWeight;
        setData({...newData});
    }

    function updateCorrect(e: ChangeEvent<HTMLInputElement>) {
        setCorrect(e.target.checked);
        const newData = {...data};
        newData.items[id].correct = e.target.checked;
        setData({...newData});
    }

    function openImageSelection(e: React.MouseEvent) {
        e.preventDefault();
        setShowingModal(true);
    }

    return (<>
            <FormGroup>
                <InputGroup>
                    {data.testOption === TestOption.Quiz ?
                        <Input type="checkbox" checked={correct} onChange={updateCorrect}
                               style={{marginRight: "10px", marginTop: "0.75em"}}
                               title={correct ? "mark option as incorrect" : "mark option as correct"}/> :
                        <div className="move-direction-container">
                            {first ? null :
                                <AiOutlineArrowUp onClick={() => moveQuestionDirection(-1)} title="move question up"/>}
                            {last ? null : <AiOutlineArrowDown onClick={() => moveQuestionDirection(+1)}
                                                               title="move question down"/>}
                        </div>}
                    <Input type="text" value={data.items[id].name} onChange={e => updateContent(e.target.value)}
                           style={{width: "75%"}}/>
                    {data.testOption === TestOption.Weighted ?
                        <Input type="number" value={weight} onChange={updateWeight} bsSize="sm"/> : null}
                    <Button color="primary" onClick={openImageSelection} title="edit description and image">
                        <AiOutlineFileImage/>
                    </Button>
                    {categories.length > 1 && <Dropdown isOpen={isOpen} toggle={() => {
                        setIsOpen(!isOpen)
                    }}>
                        <DropdownToggle color="primary" tabIndex={-1}
                                        title="move to different category"><MdDriveFileMoveOutline/> </DropdownToggle>
                        <DropdownMenu>
                            {categories.map((category, index) => {
                                return <DropdownItem key={index}
                                                     onClick={() => moveQuestion(category)}>{category}</DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>}


                    <Button color="danger" onClick={() => removeQuestion(id)}
                            size="sm" tabIndex={-1} title="delete Question"><AiOutlineDelete/></Button>
                </InputGroup>
            </FormGroup>
            {showingModal ? <ImageSelector toggleModal={() => setShowingModal(false)} questionId={id}/> : null}
        </>
    )
}