import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataContext} from "./InspectTest";

export function MemeCard({
                             undoMeme,
                             selectedResult,
                             setSelectedResult
                         }: { undoMeme: (index: number) => void, selectedResult: number, setSelectedResult: (_: number) => void }) {
    const fileRef = useRef<HTMLInputElement>();
    const [key, setKey] = useState("a");
    const [memeUnity, setMemeUnity] = useState(false);
    const [meme, setMeme] = useState<string | File>("");
    const [updated, setUpdated] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const {data, setData} = useContext(DataContext);

    useEffect(() => {
        if (selectedResult === -1) {
            setMemeUnity(true);
            resetSelection();
            setMeme(data.texts.meme as string);
            setUpdated(typeof data.texts.meme === typeof {});

        } else {
            setMemeUnity(false);
            setMeme(data.texts.results[selectedResult].meme as string);
            setUpdated(typeof data.texts.results[selectedResult].meme === typeof {});

        }
        setInitialized(true);
    }, [selectedResult]);

    useEffect(() => {
        if (!initialized) {
            return;
        }
        if (memeUnity) {
            setMeme(data.texts.meme as string);
            setSelectedResult(-1);
        } else {
            setMeme(data.texts.results[0].meme as string);
            setSelectedResult(0);
            delete data.texts.meme;
        }
    }, [memeUnity, initialized]);

    function resetSelection() {
        fileRef.current!.value = "";
        setKey(Math.random().toString(36));
        undoMeme(selectedResult);
        setUpdated(false);
    }

    function updateMeme(file: string | File) {
        const newData = {...data};
        setMeme(file);
        if (memeUnity) {
            newData.texts.meme = file;
        } else {
            delete newData.texts.meme;
            newData.texts.results[selectedResult].meme = file;
        }
        setData({...newData});
        setUpdated(true);
    }

    // @ts-ignore
    return (
        <Card>
            <CardHeader tag="h3" style={{textAlign: "center"}}>Meme</CardHeader>
            <CardBody align="center">
                {meme ?
                    <img
                        src={updated ? URL.createObjectURL(meme as File) : meme as string}
                        alt="meme"
                        style={{width: "50%", height: "auto", marginBottom: "10px"}}/> : null}
                <FormGroup>
                    <Input type="file" onChange={e => updateMeme((e.target.files as FileList)[0])}
                           accept={".jpg, .jpeg, .png"}
                           innerRef={fileRef as React.MutableRefObject<HTMLInputElement>}
                           key={key} title="select a meme that will be shown on the result page"/>
                </FormGroup>
            </CardBody>
            <CardFooter align="center">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label tag="h4">
                                Same meme for all results
                            </Label>
                            <Input type="checkbox" onChange={() => setMemeUnity(!memeUnity)}
                                   checked={memeUnity}/>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button color="danger" onClick={resetSelection}
                                title="undo any changes that have been made to the selected meme">Undo</Button>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}