import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label} from "reactstrap";
import {DataContext} from "./InspectTest";
import {useContext, useState} from "react";
import {TestOption} from "./TestType";
import React from "react";

export function TestHeader({categories}: {categories: string[]}) {

    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
    const [testDropdownOpen, setTestDropdownOpen] = useState(false);

    const {data, setData} = useContext(DataContext);

    return <>
        <FormGroup>
            <Label tag="h3">Category:</Label>
            <Dropdown isOpen={categoryDropdownOpen}
                      toggle={() => setCategoryDropdownOpen(!categoryDropdownOpen)} className="category-dropdown">
                <DropdownToggle caret color="primary">
                    {data.category}
                </DropdownToggle>
                <DropdownMenu>
                    {categories.map((category, index) => {
                        return <DropdownItem key={index}
                                             onClick={() => setData({...data, category: category})}>{category}</DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>
        </FormGroup>
        <FormGroup>
            <Label tag="h3">Test option:</Label>
            <Dropdown isOpen={testDropdownOpen}
                      toggle={() => setTestDropdownOpen(!testDropdownOpen)}
                      className="category-dropdown">
                <DropdownToggle caret color="primary">
                    {data.testOption}
                </DropdownToggle>
                <DropdownMenu>
                    {Object.values(TestOption).map((option, index) => {
                        return <DropdownItem key={index}
                                             onClick={() => setData({...data, testOption: option})}>{option}</DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>
        </FormGroup>
    </>;
}