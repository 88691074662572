export type TestType = {
    items: Array<TestItem>;
    texts: TextsType
    category: string;
    testOption: TestOption;
    categoryOrder: Array<string>;
}

export type TestItem = {
    "name": string
    "category": string,
    "weight"?: number,
    correct?: boolean,
    description?: string,
    image?: string,
}

export enum TestOption {
    Weighted = "Weighted",
    Score = "Score",
    Quiz = "Quiz"
}

export type TextsType = {
    title: string,
    results: Array<{ title: string; body: string; start?: string, meme?: string | File }>,
    description: string,
    meme?: string | File,
}