import {Button, Col, FormGroup, Input, InputGroup, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {AiOutlineCheck, AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {DataContext} from "./InspectTest";

export function ResultPart({
                               index,
                               editResult,
                               selected,
                               setSelected,
                               memeUnity
                           }: { index: number, editResult: (id: number, newText?: string, key?: string) => void, selected: boolean, setSelected: (_: number) => void, memeUnity: boolean }) {
    const {data, setData} = useContext(DataContext);

    const [editingTitle, setEditingTitle] = useState(false);
    const [startWeight, setStartWeight] = useState(data.texts.results[index].start || 0);

    function selectResult() {
        if (!memeUnity) {
            setSelected(index);
        }
    }

    function updateStartWeight(e: React.ChangeEvent<HTMLInputElement>) {
        const newStartWeight = e.target.value;
        setStartWeight(newStartWeight);
        const newData = {...data};
        newData.texts.results[index].start = newStartWeight;
        setData({...newData});
    }

    return (
        <FormGroup
            className={`${memeUnity ? null : "single-result"} ${selected ? "selected-result" : ""}`}
            onClick={selectResult}>
            <Row style={{marginBottom: "10px"}} xs="12">
                <Col xs="10">
                    {editingTitle ?
                        <Input type="text" value={data.texts.results[index].title}
                               onChange={e => editResult(index, e.target.value, "title")}
                               style={{marginBottom: "5px"}}/> :
                        <h5>{data.texts.results[index].title}</h5>}
                </Col>
                <Col xs="2" style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button color="primary" onClick={() => setEditingTitle(!editingTitle)}
                            tabIndex={-1} title={editingTitle ? "save new name" : "edit result name"}>
                        {editingTitle ? <AiOutlineCheck/> : <AiOutlineEdit/>}
                    </Button>
                    <Button color="danger" onClick={() => editResult(index, undefined, "title")}
                            tabIndex={-1} title="delete result">
                        <AiOutlineDelete/>
                    </Button>
                </Col>
            </Row>
            <InputGroup>
                <Input type="text" value={data.texts.results[index].body}
                       onChange={e => editResult(index, e.target.value, "body")}
                       style={{width: "85%"}}/>
                <Input type="number" value={startWeight} onChange={updateStartWeight}
                       style={{textAlign: "center"}} title="edit the score where this result starts"/>
            </InputGroup>
        </FormGroup>
    )
}