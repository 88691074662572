import React, {useContext, useEffect, useState} from "react";
import {FirebaseContext} from "../../App";
import {getBytes, getStorage, listAll, ref, uploadBytes} from "firebase/storage";
import {useNavigate} from "react-router-dom";
import {Button, Col, Row, Table} from "reactstrap";
import {GlobalType} from "../Test/GlobalType";

export function Home() {
    const [unconfirmedTests, setUnconfirmedTests] = useState<Array<{ id: string, name: string }>>([]);
    const [confirmedTests, setConfirmedTests] = useState<GlobalType["tests"]>([]);
    const [data, setData] = useState<GlobalType>({} as GlobalType);

    const navigate = useNavigate();

    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        void loadTests();
    }, []);

    async function loadTests() {
        const storage = getStorage(firebase);

        const buffer = await getBytes(ref(storage, "/tests.json"));
        const decoder = new TextDecoder("utf-8");
        const tests: GlobalType = JSON.parse(decoder.decode(buffer));
        setData(tests);
        setConfirmedTests(tests.tests);

        const rootContent = await listAll(ref(storage, "/tests"));
        const newTests: { id: string, name: string }[] = [];
        const nameToIdMapping = localStorage.getItem("nameToIdMapping") ? JSON.parse(localStorage.getItem("nameToIdMapping") as string) : {};
        rootContent.prefixes.forEach(prefix => {
            if (tests.tests.filter(test => test.id === prefix.name).length === 0) {
                newTests.push({
                    id: prefix.name,
                    name: nameToIdMapping[prefix.name],
                });
            }
        });
        setUnconfirmedTests(newTests);
    }

    async function createTest() {
        const newTestId = generateTestId();
        const storage = getStorage(firebase);
        const title = prompt("Enter test title");
        const data = {
            items: [],
            texts: {
                title: title,
                results: [],
                description: ""
            },
            category: "general",
        }
        const encoder = new TextEncoder();
        const buffer = encoder.encode(JSON.stringify(data));
        await uploadBytes(ref(storage, `/tests/${newTestId}/data.json`), buffer);
        const nameToIdMapping = localStorage.getItem("nameToIdMapping") ? JSON.parse(localStorage.getItem("nameToIdMapping") as string) : {};
        nameToIdMapping[newTestId] = title;
        localStorage.setItem("nameToIdMapping", JSON.stringify(nameToIdMapping));
        navigate(`/edit/${newTestId}`);
    }

    function generateTestId(retries: number = 0): string {
        const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const allTests = [...unconfirmedTests, ...confirmedTests];
        if (allTests.filter(test => test.id === id).length > 0) {
            if (retries < 10) {
                return generateTestId(retries + 1);
            } else {
                throw new Error("Could not generate a unique test id");
            }
        }
        return id;
    }

    async function createNewCategory() {
        const newCategory = prompt("Enter new category name");
        if (!newCategory) {
            return;
        }
        const storage = getStorage(firebase);
        const newData = {...data};
        newData.categories.push(newCategory);
        setData(newData);
        const encoder = new TextEncoder();
        const buffer = encoder.encode(JSON.stringify(newData));
        await uploadBytes(ref(storage, `/tests.json`), buffer);
    }


    return (
        <div>
            <Table dark bordered>
                <thead>
                <tr>
                    <th>Published Tests</th>
                    <th>Unpublished Tests</th>
                </tr>
                </thead>
                <tbody>
                {renderTableBody(unconfirmedTests, confirmedTests)}
                </tbody>
            </Table>
            <Row align="center" style={{width: "100%"}}>
                <Col>
                    <Button color="primary" onClick={createTest}>Add Test</Button>
                </Col>
                <Col>
                    <Button color="primary" onClick={createNewCategory}>Add category</Button>
                </Col>
            </Row>
        </div>
    )
}

function renderTableBody(unpublishedTests: Array<{ id: string, name: string }>, confirmedTests: GlobalType["tests"]) {
    const highestIndex = Math.max(unpublishedTests.length, confirmedTests.length);
    const rows = [];
    for (let i = 0; i < highestIndex; i++) {
        rows.push(
            <tr key={i}>
                <td>{confirmedTests[i] ?
                    <a href={`/edit/${confirmedTests[i].id}`}>{confirmedTests[i].name}</a> : ""}</td>
                <td>{unpublishedTests[i] ?
                    <a href={`/edit/${unpublishedTests[i].id}`}>{unpublishedTests[i].name ??
                        unpublishedTests[i].id}</a> : ""}</td>
            </tr>
        )
    }
    return (rows);
}