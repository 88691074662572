import {Button, Container, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import {
    useCreateUserWithEmailAndPassword,
    useSignInWithEmailAndPassword
} from "react-firebase-hooks/auth";
import {FirebaseContext} from "../../App";
import {getAuth} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export function Login() {
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [signup, setSignup] = useState(false);
    const [ready, setReady] = useState(false);

    const navigate = useNavigate();

    const firebase = useContext(FirebaseContext);
    const auth = getAuth(firebase);

    const [signInWithEmailAndPassword, loginError] = useSignInWithEmailAndPassword(auth);
    const [createUserWithEmailAndPassword, createError] = useCreateUserWithEmailAndPassword(auth);

    useEffect(() => {
        if (signup) {
            setPasswordValid(password.length >= 12);
        }
    }, [password]);

    useEffect(() => {
        setReady(email.length > 0 && password.length >= 0 && (!signup || confirmPassword === password));
    }, [email, password, signup, confirmPassword]);

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmailValid(re.test(String(e.target.value).toLowerCase()));
    }

    function switchToSignUp(e: React.MouseEvent) {
        e.preventDefault();
        setSignup(true);
        setPasswordValid(password.length >= 12);
    }

    function switchToLogin(e: React.MouseEvent) {
        e.preventDefault();
        setSignup(false);
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (signup) {
            await createUserWithEmailAndPassword(email, password)
        } else {
            await signInWithEmailAndPassword(email, password)
        }
        if (loginError || createError) {
            console.log(loginError || createError);
        } else {
            navigate("/");
        }
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit} invalid={createError || loginError}>
                <FormGroup>
                    <Label>Email</Label>
                    <Input type="email" value={email} onChange={handleEmailChange}
                           valid={emailValid && signup}
                           invalid={!emailValid && signup}/>
                    <FormFeedback invalid>Please enter a valid email address</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Input type="password" value={password}
                           onChange={e => setPassword(e.target.value)}
                           valid={passwordValid && signup} invalid={!passwordValid && signup}/>
                    {signup ? <FormFeedback invalid>Password must be at least 12 characters
                        long</FormFeedback> : null}
                </FormGroup>
                {signup ? <FormGroup>
                        <Label>Confirm password</Label>
                        <Input type="password" value={confirmPassword}
                               onChange={e => setConfirmPassword(e.target.value)}
                               valid={password === confirmPassword && password !== ""}
                               invalid={password !== confirmPassword && confirmPassword !== ""}/>
                        <FormFeedback invalid>Passwords do not match</FormFeedback>
                    </FormGroup> :
                    null}
                <Row>
                    <Button color="success"
                            disabled={!ready}>{signup ? "Sing up" : "login"}</Button>
                </Row>
                {signup ?
                    <p>already have an account? <a href="#" onClick={switchToLogin}>log in</a></p> :
                    <p>not a member yet? <a href="#" onClick={switchToSignUp}>sign up</a></p>}
                <FormFeedback invalid>Something went wrong</FormFeedback>
            </Form>
        </Container>
    )
}